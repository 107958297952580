import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "./scss/custom-style.css";
import PartnersTerm from "./authPages/partnersTerm";
import RegisterNew from "./views/a_pooplog/new-auth/register-new";
import "bootstrap/dist/css/bootstrap.min.css";
// export const AuthContext = createContext();

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// Pages
const Login = React.lazy(() => import("./authPages/login"));
const ForgotPassword = React.lazy(() => import("./authPages/forgot-password"));
const PasswordReset = React.lazy(() => import("./authPages/password-reset"));
const Register = React.lazy(() => import("./authPages/register"));
const VerifyPage = React.lazy(() => import("./authPages/verifyPage"));
const RegisterPartner = React.lazy(() =>
  import("./authPages/registerPartners")
);

const TestPage = React.lazy(() => import("./views/layout/testPage"));
const WelcomePage = React.lazy(() =>
  import("./authPages/registerType/welcomePage")
);
const TermAndCondition = React.lazy(() =>
  import("./authPages/term-and-condition")
);
const PrivacyPolicy = React.lazy(() => import("./authPages/privacy-policy"));

// const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
// const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/welcome-page"
            name="Welcome Page"
            render={(props) => <WelcomePage {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/reset-password"
            name="Reset Password"
            render={(props) => <PasswordReset {...props} />}
          />
          <Route
            exact
            path="/terms-of-service"
            name="Terms Page"
            render={(props) => <TermAndCondition {...props} />}
          />
          <Route
            exact
            path="/partner-terms"
            name="Partner Terms"
            render={(props) => <PartnersTerm {...props} />}
          />
          <Route
            exact
            path="/privacy-policy"
            name="Terms Page"
            render={(props) => <PrivacyPolicy {...props} />}
          />
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register-partner"
            name="Partners Page"
            render={(props) => <RegisterPartner {...props} />}
          />
          <Route
            exact
            path="/verify-email"
            name="Verify Page"
            render={(props) => <VerifyPage {...props} />}
          />
          <Route
            exact
            path="/test-page"
            name="Test Page"
            render={(props) => <TestPage {...props} />}
          />
          <Route
            exact
            path="/register-new"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <RegisterNew {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </Router>
  );
  // }
};

export default App;
